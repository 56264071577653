import { createCase } from "./strapiCreate"

const _setUser = user => window.localStorage.setItem(`hrbcUser`, JSON.stringify(user))


const isBrowser = () => typeof window !== "undefined"


const getUser = () =>
  isBrowser() && window.localStorage.getItem(`hrbcUser`)
    ? JSON.parse(window.localStorage.getItem(`hrbcUser`)).user
    : {}

const getToken = () =>
  isBrowser() && window.localStorage.getItem(`hrbcUser`)
    ? JSON.parse(window.localStorage.getItem(`hrbcUser`)).jwt
    : {}


const handleLogin = ({ username, password }) => {

  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({ "identifier": username, "password": password });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
console.warn(process.env.CMS_URL)
  return fetch(`${process.env.CMS_URL}/auth/local`, requestOptions)
    .then(response => response.text())
    .then(result => {
      console.log('results',result)
      const strapiRes = JSON.parse(result)
      if (strapiRes.statusCode >= 400 && strapiRes.statusCode < 500) {
        return { success: false, message: strapiRes.message }
      } else if (strapiRes.jwt) {
        // let sampleResponse = {jwt: "asdfasdf.asdfasdf.asdfasdf",user: {email: "asdf@asdf.asdf",id: 1,provider: "local",role: {description: "These users have all access in the project.",id: 1,name: "Administrator",type: "root"},username: "admin"}}
        _setUser(strapiRes)
        return { success: true }
      } else {
        console.error({ success: false, message: strapiRes })
        return { success: false, message: strapiRes }
      }
    })
    .catch(error => {
      console.error('error', error)
      return { success: false, message: `There was an error` }
    })
}


const isLoggedIn = () => {
  const user = getUser()
  return !!user
}


const logout = callback => {
  _setUser({})
  callback()
}



const registerUser = (data) => {
  const myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")

  const raw = JSON.stringify({
    "username": data.username,
    "email": data.email,
    "password": data.password,
    "name" : {
      "first_name": data.first_name,
      "last_name": data.last_name
    }
  })

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return fetch("http://localhost:1337/auth/local/register", requestOptions)
    .then(response => response.text())
    .then(result => {
      const strapiRes = JSON.parse(result)
      console.log('registry response',strapiRes)
      if (strapiRes.statusCode >= 400 && strapiRes.statusCode < 500) {
        return { success: false, message: strapiRes.message }
      } else if (strapiRes.jwt) {
        _setUser(strapiRes)
        createCase({
          subject: "New User", 
          description: `${strapiRes.user.name.first_name} ${strapiRes.user.name.last_name} has requested to register as a ${data.role}`, 
          created_by_user_id: getUser().id
        })
        return { success: true }
      } else {
        console.error({ success: false, message: strapiRes })
        return { success: false, message: strapiRes }
      }
    })
    .catch(error => {
      console.error('error', error)
      return { success: false, message: `There was an error` }
    })
}


const isGuestUser = () => {
  return getUser().role.type === `public`
}



export {
  isBrowser,
  getUser,
  getToken,
  handleLogin,
  isLoggedIn,
  logout,
  registerUser,
  isGuestUser
}