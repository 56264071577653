import { getUser } from "./auth";

export const createCase = (data) => {
    return new Promise((resolve, reject) => {
        const userId = getUser() ? getUser().id : ''
        console.log('MYCASE',{ ...data, created_by: { id: userId } })
        resolve(create('cases',{ ...data, created_by: { id: userId } }))
    })
}


const create = async (strapiType,data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify({"subject":"{general}","description":"{message}","name":"{name}","email":"{email}"});
    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`http://localhost:1337/${strapiType}`, requestOptions);
        const result = await response.text();
        return { success: true, data: JSON.parse(result) };
    }
    catch (error) {
        return { success: false, error: error }
    }
}