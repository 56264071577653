import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import { Link, graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import UserNav from './userNav'
import { Component } from 'react'



export default class NavClass extends Component {

  navInitialStlye

  distanceToHeader

  componentDidMount() {
    const aNav = document.querySelector('#dummy')
    this.navInitialStlye = aNav.style
    this.distanceToHeader = aNav.offsetTop

    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll = event => {
    const navElement = document.querySelector('#mainNav')
    const dummy = document.querySelector('#dummy2')

    if (window.pageYOffset < this.distanceToHeader) {
      navElement.style = this.navInitialStlye
      dummy.style.padding = '0'
    } else {
      navElement.style.backgroundColor = 'rgba(51, 51, 51, 0.9)'
      navElement.style.padding = '0'
      dummy.style.padding = '24px 0'
    }
  }

  render() {
    return <SiteNav />
  }
}


const SiteNav = () => {
  const data = useStaticQuery(navQuery)

  console.log(data)

  let alertPanel
  if (data.strapiNavAlert.body) {
    alertPanel = (
      <Container fluid className="banner-alert" style={JSON.parse(data.strapiNavAlert.minstyles)}>
        <Container>
          <ReactMarkdown style={{ marginBottom: '0' }} source={data.strapiNavAlert.body} />
        </Container>
      </Container>
    )
  }

  const loginUtils = (
    <Container fluid style={{ position: 'relative' }}>
      <UserNav />
    </Container>
  )

  return (
    <>
      {loginUtils}
      {alertPanel}
      <div id="dummy"></div>
      <Navbar collapseOnSelect expand="lg" bg="" variant="dark" id="mainNav">
        <Container>
          {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
          <Navbar.Toggle aria-controls="navbarResponsive" style={{ border: 'none' }} />
          <Navbar.Collapse id="navbarResponsive" style={{ borderColor: 'none' }}>
            <Nav className="mx-auto px-3">
              {data.strapiNavBar.item.map((item, index) => {
                if (item.label)
                  return (
                    <Nav.Item ley={index} as="li" className={`px-md-4 text-uppercase text-expanded`}>
                      {item.external
                        ? <a href={item.link} className="nav-link">{item.label}</a>
                        : <Link to={item.link} className="nav-link" activeClassName="active">{item.label}</Link>}
                    </Nav.Item>
                  )
                else
                  return (
                    <NavDropdown title="Ministries" id="collasible-nav-dropdown" className="px-md-4 text-uppercase text-expanded">
                      {item.item.map((ddItem, ddIndex) => (
                        <Nav.Item key={ddIndex} as="li" className={`px-md-4 text-uppercase text-expanded`}>
                          {item.external
                            ? <a href={ddItem.link} className="nav-link">{ddItem.label}</a>
                            : <Link to={ddItem.link} className="nav-link" activeClassName="active">{ddItem.label}</Link>}
                        </Nav.Item>
                      ))}
                      {/* <NavDropdown.Divider /> */}
                    </NavDropdown>
                  )
              })}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div id="dummy2"></div>
    </>
  )
}

const navQuery = graphql`{
  strapiNavAlert {
    body
    minstyles
  }
  strapiNavBar {
    item {
      external
      label
      item {
        label
        link
        style
        external
      }
      style
      link
      dropdown_label
    }
  }
}
`

// export default SiteNav