import React from "react"
import { Link, navigate } from "gatsby"
import { getUser, isLoggedIn, logout } from "../services/auth"

export default function NavBar() {

  return (
    <div
      style={{
        position: 'absolute',
        bottom: '0',
        right: '0',
        color: '#C7C7C7'
      }}
    >
      <nav>
        {isLoggedIn() ? (
          <>
            <Link to="/dashboard" style={lightGreyLinks}>My Dashboard</Link>
            <span>|</span>
            <a href="/" style={lightGreyLinks} 
              onClick={event => {
                event.preventDefault()
                logout(() => navigate('/login'))
              }}
            >
              Logout
          </a>
          </>
        ) : <Link to="/login" style={lightGreyLinks}>Login</Link>}
      </nav>
    </div>
  )
}

const lightGreyLinks = {
  color: '#C7C7C7',
  fontFamily: 'raleway',
  margin: '0 6px',
  fontSize: '0.75em',
  textTransform: 'uppercase'
}