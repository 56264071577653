import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useStaticQuery, Link } from 'gatsby'
import logo from '../assets/img/transparent-logo_inverted.png'


const Footer = () => {
  const data = useStaticQuery(linksQuery)
  console.log('footer links', data)

  const linksAsLis = []
  data.strapiPartnerLinks.Link.forEach(l => {
    linksAsLis.push(<li><a key={l.id} href={l.link}>{l.label}</a></li>)
  })

  const sitemapAsLis = []
  data.strapiSitemap.Link.forEach(l => {
    sitemapAsLis.push(<li><Link key={l.id} to={l.link}>{l.label}</Link></li>)
  })


  return (
    <>
      <footer className="footer text-faded text-center py-5">
        <Container>
          <Row>
            <Col lg={6} className="color6">
              <h4 className="footer-heading">Links</h4>
              <hr className="tight" />
              <Row>
                <div id="external-links" className="footer-links color1">
                  <ul>
                    {linksAsLis}
                  </ul>
                </div>
              </Row>
            </Col>
            <Col lg={6} className="color6">
              <Row>
                <Col lg={6} md="push-1" className="color4 footer-links">
                  <h4 className="footer-heading">Sitemap</h4>
                  <hr className="tight" />
                  <ul>
                    {sitemapAsLis}
                  </ul>
                </Col>
                <Col lg={6} className="color5">
                  <h4 className="footer-heading">Contact Info</h4>
                  <hr className="tight" />
                  <Row>
                    <Col sm={6} md={6} lg={12} className="footer-links">
                      <ul>
                        <li>226 Harmony Rd. South, <br /> Oshawa, ON, Canada<br />L1H 6T7</li>
                        <li><a href="tel://9057282322">905-728-2322</a></li>
                        <li><a href="mailto:harmonychurch@rogers.com">harmonychurch@rogers.com</a></li>
                      </ul>
                    </Col>
                    <Link to='/' sm={6} md={6} lg={12} className="col color2">
                      <img src={logo} width="70%" />
                    </Link>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <p className="m-0 small">Copyright &copy; Harmony Road Baptist Church {new Date().getFullYear()}</p>
        </Container>
      </footer>
    </>
  )
}

export default Footer


const linksQuery = graphql`{
  strapiPartnerLinks {
    Link {
      css_class
      style
      link
      label
      id
      external
    }
  }
  strapiSitemap {
    Link {
      link
      label
      external
      css_class
    }
  }
}
`